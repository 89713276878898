@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: "barlow";
  src: url("/src/assets/font/barlow_font/barlow.light-webfont.woff");
  src: url("/src/assets/font/barlow_font/barlow.light-webfont.woff") format("woff"),
}

.leaflet-control-measure-interaction.js-interaction {
  padding: 0.5rem;
  background: white;
}

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

ul.tasks {
  margin-block-start: 0px !important;
  padding: 0;
  display: flex;
}

a.js-start.start {
  width: 100%;
}

img.measure_icon {
  width: 33px;
}

a.leaflet-control-measure-toggle.js-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

// .leaflet-control-measure a:active{

//   background-color: #000 !important;

// }
// .polyline-measure-unicode-icon{
//   background-color: #000 !important; 
// }


ul {
  list-style: none;
}

.leaflet-touch .leaflet-bar a {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: 2.6rem;
  height: 2.6rem;
  /* min-width: 30px; */
  line-height: normal;
  border-radius: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border-bottom: unset;
  border-radius: unset;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
}

.tasks li {
  margin: 0 5px;
  flex: 1;
  background: #f3f2f2;
  display: flex;
  justify-content: center;
  border-radius: 7px;
}

.tasks li a {
  background: none;
}

html,
body {
  height: 100%;
}

body {
  overflow: hidden;
  margin: 0;
  position: fixed;
  width: 100vw;
  bottom: 0;
  font-family: 'barlowlight' !important;
  letter-spacing: 0px;
  color: #1D1D1D;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  display: flex;
  flex-grow: 1;
  margin-right: 0px;
  text-align: center;
  flex: 1;
  justify-content: center;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-expansion-panel-header.mat-expanded {
  height: fit-content;
  border-top: solid 1px black;
  border-radius: 0;
  margin-bottom: 10px;
  padding-top: 6px;
  font-weight: bold;
}

button {
  border: none;
  padding: 0.5rem;
  margin: 0.3rem;
  cursor: pointer;
}
.boussole-content{
  position: absolute;
  top: 3rem;
  right: 0;
}

.mat-radio-button {
  margin-right: 1rem;
  display: flex !important;
  align-items: center;
}

.mat-radio-button {
  margin-right: 1rem;
}

main.header {
  padding: 1rem;
  display: flex;
  align-items: center;
}

.mat-icon {
  background-repeat: no-repeat;
}

mat-icon.mat-icon.notranslate.icon.material-icons.mat-icon-no-color {
  display: flex;
  height: 18px;
  width: 18px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.w-100 {
  width: -webkit-fill-available;
  width: -moz-available;
}

button.accent {
  background: #55AAA0;
  color: white;
  height: 40px;
  margin: 0.4rem 0;
}

button.accent:hover {
  border: solid 1px #55AAA0;
  background: white;
  color: #55AAA0;
  transition: all 0.5s;
}

button.small_btn:hover {
  background: white;
  color: #55AAA0;
  transition: all 0.5s;
}

app-polygone {
  flex: 1;
  display: flex;
  flex-direction: column;
}

mat-form-field.mat-form-field {
  background: none;
  flex: 1;
  width: 100%;
  height: 100%;
}

.mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
  cursor: pointer;
  background: none;
}

.plotForm .mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex {
  height: 100%;
  display: flex;
  align-items: center;
}

.plotForm .mat-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-form-field-flex :hover {
  background-color: #e2e2e2;
}

.plotForm .mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -1.2em;
}

.plotForm .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  transform: unset !important;
}

.mat-form-field-label-wrapper {
  top: -2em;
  padding-top: 0.84375em;
  font-size: unset;
  color: black;
  opacity: 1;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
  transform: unset;
  width: unset;
}

.mat-form-field-label {
  color: rgb(44 44 44);
}

.selector .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 4px 4px 0 0;
  padding: .75em 0em 0 0em;
}

mat-icon.mat-icon.notranslate.small_icon.material-icons.mat-icon-no-color {
  font-size: 18px;
  height: 10px;
  width: 17px;
  display: flex;
  align-items: center;
}

app-login {
  display: flex;
  flex: 1;
  flex-direction: column;
}

app-register {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 5vw;
}

app-email-component,
app-password-component {
  flex: 1;
}

mat-icon.mat-icon.notranslate.orange_icon.material-icons.mat-icon-no-color {
  color: #ec9900;
  font-size: 1.5rem;
}

mat-icon.mat-icon.notranslate.orange_icon_count.material-icons.mat-icon-no-color {
  color: #ec9900;
  font-size: 1rem;
  height: 1rem;
  width: 1rem;
}

.mat-progress-bar-buffer {
  background-color: #a4c2bd !important;
}

mat-icon.mat-icon.notranslate.guestStatus.material-icons.mat-icon-no-color {
  color: #ec9900;
  font-size: 1.5rem;
}

.mat-progress-bar-fill::after {
  background-color: #55aaa0 !important;
}

mat-icon.mat-icon.notranslate.green_icon.material-icons.mat-icon-no-color {
  color: #0080009c;
  font-size: 1.5rem;
}

mat-icon.mat-icon.notranslate.green_icon_count.material-icons.mat-icon-no-color {
  color: #0080009c;
  font-size: 1rem;
  height: 1rem;
  width: 1rem;
}

button.small_btn {
  height: 1.8rem;
  background: #55aaa0;
  color: white;
}

mat-icon.mat-icon.notranslate.icon.clear-icon.material-icons.mat-icon-no-color {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

input {
  outline: 0;
}

.mat-form-field-flex {
  padding: 0 !important;
}

user agent stylesheet div {
  display: block;
}

.cultures {
  margin-top: 0.7rem;
}

.plots {
  background: white;
  margin: 0.5rem 0;
  padding: 0.3rem;
  border-radius: 0.5rem;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  height: 0px !important;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: unset !important;
}

.mat-form-field-infix {
  padding: unset !important;
  border-top: unset !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0.5em !important;
}

.plotForm .mat-form-field-wrapper {
  height: 100%;
}

.cultures {
  background: #f7f7f7;
  margin-top: 0.7rem;
  width: 98%;
  display: flex;
}

::ng-deep .mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-fill .mat-form-field-ripple {
  bottom: 0 !important;
  height: 0px !important;
}

app-select {
  margin: 1.5rem 0 0;
  border-radius: 0.2rem;
  height: 1.5rem;
  background: #2828281A 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: -webkit-fill-available;
  padding: 5px;
  padding-top: 0.5rem;
}

.iti--allow-dropdown input,
.iti--allow-dropdown input[type=text],
.iti--allow-dropdown input[type=tel],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type=text],
.iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
  height: 2.6rem;
  // border: 1px solid #707070;
  border-radius: 0.3rem;
  background: #2828281A 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: row;
  flex: 1;
  border: none;
  width: -webkit-fill-available;
  width: -moz-available;
}


.read_only .iti--allow-dropdown input {
  border: unset !important;
  color: #55AAA0;
  background: unset;
  font: normal normal normal 20px/24px "barlowlight";
  letter-spacing: 0px;
  color: #55AAA0;
  opacity: 1;

}

.iti {
  position: relative;
  display: inline-block;
  display: flex;
  flex: 1;
}


.set_type {
  font-size: 0.85rem;
}

.mat-radio-outer-circle {
  height: 15px !important;
  width: 15px !important;
}

.mat-radio-button .mat-radio-ripple {
  height: 25px !important;
  width: 25px !important;
}

.mat-radio-label-content {
  padding-left: 0px !important;
}

.row {
  display: flex;
  align-items: center;
}

main {
  /* position: relative; */
}

.clear_icon {
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 0;
  z-index: 1000;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 30px !important;
  width: 30px !important;
}

.clear_icon:hover {
  background: #55aaa0;
  color: white;
  transition: all 0.3s;
}

span.wait_msg {
  position: absolute;
  bottom: 0;
  top: 2.5rem;
  color: black;
  font-size: 0.6rem;
}

button.custum_btn {
  position: relative;
}

.custum_btn {
  position: absolute;
}

.input_container {
  border: none;
  height: 1.7rem;
  border-radius: 0.4rem;
}

.input_container_update {
  border: none;
  height: 2.5rem;
  border-radius: 0.4rem;
}

textarea.input_container {
  height: 6rem !important;
}


span.help {
  color: black;
  font-size: 0.8rem;
  text-align: center;
  display: block;
  margin: 1rem;
  font-family: "barlowlight";
}

.tool_title {
  text-align: center;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(186 219 188) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #55aaa0 !important;
}

td.mat-calendar-body-cell {
  pointer-events: none;
}

.map_date.mat-calendar-body-cell {
  background: white;
  border-radius: 100%;
  color: white;
  font-weight: bold;
  pointer-events: auto;
}

.map_date .mat-calendar-body-cell-content.mat-focus-indicator {
  position: absolute;
  height: 27px;
  width: 27px;
  border-radius: 1rem;
  background: #55aaa0;
  color: white;
  font-weight: bold;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #55aaa0;
}

.custom-note {
  width: 18rem;
  background: #e0e0e0;
}
.custom_count_number{
  width: 5rem;
}

h6.note_title {
  font-size: 3rem;
  text-align: left;
  border-radius: 0.4rem;
}

.pop_note_delete img {
  width: 1rem;
  height: auto;
}

.pop_note_update img {
  width: 1rem;
  height: auto;
}
.pop_note_itineraire img{
  width: 1rem;
  height: auto;
}



.desc {
  font-size: 1rem;
  font-weight: 200;
  padding: 1rem 0;
}

.note_popup {
  margin-top: 2rem;
}

img.note_img {
  height: 7rem;
  border-radius: 0.5rem;
}

.images {
  position: relative;
  max-height: 8rem;
  /* overflow: hidden; */
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
}

.img_content {
  height: 8rem;
  min-width: 8rem;
  margin: 0 0.2rem;
  border-radius: 0.4rem;
}

mat-icon.mat-icon.notranslate.material-icons.mat-icon-no-color {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-div-icon {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  color: white;
  background: black;
  font-weight: bold;
   border-radius: 0;
   border: unset;
}

.my-div-icon div {
  font-size: 11px;
}

mat-icon.mat-icon.notranslate.material-icons.mat-icon-no-color {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none !important;
}

mat-icon.mat-icon.notranslate.empty_icon.material-icons.mat-icon-no-color {
  font-size: 6rem;
  height: 6rem;
  width: 6rem;
  color: #80808059;
}

.empty_not {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #55aaa0 !important;
}

.example-box {
  width: 200px;
  height: 200px;
  border: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
              0 2px 2px 0 rgba(0, 0, 0, 0.14),
              0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.example-box:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.example-handle {
  position: absolute;
  color: #ccc;
  cursor: move;
  width: 24px;
  height: 24px;
}

.mat-radio-inner-circle {
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 28px !important;
  left: -5px !important;
  position: absolute;
  top: -7px !important;
  width: 25px !important;
  transform: scale(0.001);
  color-adjust: exact;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #55aaa0;
}

.alert-danger {
  font: normal normal 300 16px/18px Helvetica Neue;
  letter-spacing: 0px;
  color: #ff0000;
  opacity: 1;
  padding: 0.2rem;
  margin-top: 0.2rem;
  border-radius: 0.4rem;
  background: #ffe0e0;
  font-size: 0.75rem;
}

/*  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 28px !important;
  left: -5px !important;
  position: absolute;
  top: -7px !important;
  width: 25px !important;
  transform: scale(0.001);
  color-adjust: exact;
} */

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #55aaa0 !important;
}

app-list-or-new {
  width: 100%;
}

.heder {
  width: 100%;
  margin: 0.7rem 0;
}

mat-icon.mat-icon.notranslate.tooltipIcon.material-icons.mat-icon-no-color {
  font-size: 1.5rem;
  color: rgb(0 0 0 / 54%);
}

button.mat-tooltip-trigger.tooltipBtn {
  background: none;
  padding: 0;
  margin-left: 1rem;
}

app-home-loading {
  position: fixed;
  width: 100vw;
}

.main-section1 .ph-row {
  background-color: white !important;
}

.section2 {
  background: white !important;
}

.section-container {
  background-color: white !important;
}

.disable {
  opacity: 0.5;
  pointer-events: none;
}

button {
  outline: none;
}

.btns-confirmation {
  width: 100%;
}

button.bouton.yes_btn {
  background: #55aaa0;
  color: white;
  flex: 1;
  height: 2.8rem;
}

button.bouton.no_btn {
  flex: 1;
}

body {
  margin: 0;
  position: fixed;
  width: 100vw;
  bottom: 0;
  font: medium medium 300 15px/24px Helvetica Neue !important;
  letter-spacing: 0px;
  color: #1D1D1D;
  overflow: auto;
}

.downloadBtn {
  background-color: #55aaa0;
  color: white;
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-top: 5px;
  border-radius: 5px;
}

.my {
  width: 11rem;
}

.zone_title {
  margin: 0.5rem;
  font-size: 1.2rem;
  color: #fafafa !important;
  text-align: center;
  font-family: "barlowlight";
}

.zone_body {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  font-size: 0.9rem;
  font-family: "barlowlight";
}

.zone_body span {
  font-size: 1.2rem;
  flex: 1;
  text-align: center;
  color: #fafafa;
  border-radius: 50%;
  font-family: "barlowlight";
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #55aaa0 !important;
  color: white !important;
}

.mat-horizontal-stepper-header {
  height: 45px !important;
}

.mat-slide-toggle-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: barlow, arial, helvetica;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
  background: unset;
}

.note_title {
  display: flex;
  flex-direction: column;
}

.note_title span {
  display: block;
}

.noting {
  font-size: 1.1rem;
  font-family: barlow light, arial, helvetica;
}

.note_date {
  font-size: 0.8rem;
  color: #000000ad;
  padding-bottom: 0.5rem;
  border-bottom: solid 1px #80808030;
}

.pop_note_update, .pop_note_delete, .pop_note_itineraire{
  border: none;
  background: #55AAA0;
  border-radius: 25px;
  margin: 0.4rem;
  padding: 0.4rem 0.5rem;
  border: 1px solid transparent;
}
.pop_note_modif_img2, .pop_note_del_img2, .pop_note_itiner_img2{
  display: none;
}

.pop_note_update:hover {
  border: 1px solid #55AAA0;
  background: #FFF;
  border-radius: 25px;
  margin: 0.4rem;
  padding: 0.4rem 0.5rem;
  .pop_note_modif_img1{
    display: none;
  }
  .pop_note_modif_img2{
    display: block;
  }
}
.pop_note_delete:hover {
  border: 1px solid #55AAA0;
  background: #FFF;
  border-radius: 25px;
  margin: 0.4rem;
  padding: 0.4rem 0.5rem;

  .pop_note_del_img1{
    display: none;
  }
  .pop_note_del_img2{
    display: block;
  }
}
.pop_note_itineraire:hover {
  border: 1px solid #55AAA0;
  background: #FFF;
  border-radius: 25px;
  margin: 0.4rem;
  padding: 0.4rem 0.5rem;

  .pop_note_itiner_img2{
    display: block;
  }
  .pop_note_itiner_img1{
    display: none;
  }
}



.noteActions {
  display: flex;
  justify-content: flex-end;
}

.read_only {
  border: unset !important;
  padding: 0 !important;
  background: unset !important;
  font: normal normal normal 20px/24px Helvetica Neue;
  letter-spacing: 0px;
  color: #55AAA0;
  opacity: 1;
  font-family: barlow light, arial, helvetica;
}

.plot_container .leaflet-popup.custom-pop-up-parcs .leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #fffdfd8f 0% 0% no-repeat padding-box;
  /* border-radius: 10px; */
  opacity: 1;
  color: #000000;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.custom-note{
  background: #fffdfd40 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #000000;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 0.5rem;
}


.leaflet-popup.plot_container.leaflet-zoom-animated {
  background: #fffdfd40 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  color: #000000;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

span.labelTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
}

.infos {
  display: flex;
  flex-direction: row;
  font-size: 0.9rem;
  padding: 5px 0;
}
mat-icon.mat-icon.notranslate.icon-goto-Detail.material-icons.mat-icon-no-color {
  background: #55AAA0;
  color: #FFF;
  border-radius: 50%;
  margin-left: 0.3rem;
  margin-top: 0.2rem;
}

span.value {
  font-weight: bold;
  padding-left: 5px;
}

img.kml-img {
  margin-right: 5px;
}

.leaflet-popup.custom-pop-up-parcs {
  background: #c70307;
  border-radius: 10px;
}

.leaflet-container a.leaflet-popup-close-button {
  color: #424f4c;
}

.cultures app-select {
  margin: 0;
  height: 1.5rem;
}

.plotForm .toogle_toogle.select_drop_down {
  top: unset !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #55aaa0;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #3a7870;
}

input.weather_btn.leaflet-control {
  width: 46px;
  height: 44px;
  background-size: 35px 35px;
  background-position: center;
  background-repeat: no-repeat;
  border: solid 1px grey;
  border-radius: 5px;
}

.leaflet-popup.leaflet-measure-resultpopup.leaflet-zoom-animated {
  background: white;
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

mat-icon.mat-icon.notranslate.dashboardicon.material-icons.mat-icon-no-color {
  display: flex;
  height: 18px;
  width: 18px;
  font-size: 37px;
  display: flex;
  align-items: center;
  color: #201e1e
}

mat-icon.mat-icon.notranslate.dashboardiconProf.material-icons.mat-icon-no-color {
  display: flex;
  height: 18px;
  width: 18px;
  font-size: 21px;
  display: flex;
  align-items: center;
  color: #000;
}

mat-icon.mat-icon.notranslate.material-icons.add-user-icon.mat-icon-no-color {
  font-size: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none !important;
}

.subactor_footer button {
  margin: auto;
}

.running {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.nav_item.is_coming {
  opacity: 0.5;
}

.nav_item.is_coming::after {
  content: 'Bientôt disponible';
}

.nav_item.is_coming::after {
  position: absolute;
  content: "Bientôt disponible";
  opacity: 0;
}

.nav_item.is_coming:hover::after {
  opacity: 1;
  transition: all 0.3s;
  background: black;
  padding: 5px;
}

.leaflet-top.leaflet-left {
  position: absolute;
  left: 25vw;
  top: 1.2rem;
  display: flex;

}

.leaflet-top {
  top: 1.2rem;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
  display: flex;
  align-items: center;
  height: 2.6rem;
}

.leaflet-control-measure-interaction.js-interaction a {
  font-size: 1rem;
  width: 100%;
  height: 2rem;
  font-size: 0.9rem;
  color: #2e2e2eb5;
  border-radius: 10px !important;
  position: unset;
}

.leaflet-control-measure-interaction.js-interaction a:active {
  .polyline-measure-unicode-icon {
    display: none !important;
  }
}

leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: unset;
  background-clip: padding-box;
}

.leaflet-control-attribution {
  display: none;
}

.carousel-cells {
  transition: transform .2s;
  width: 80%;
  height: 100%;
  display: block;
  will-change: transform;
}

.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.carousel-container {
  overflow: hidden;
  width: 70%;
  height: 100%;
  cursor: grab;
  display: flex;
  flex-direction: row;
  margin: auto;
}

.blur_bg {
  background: #ffffff66 0% 0% no-repeat padding-box;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
}

@-moz-document url-prefix() {
  .blur_bg {
    background: #ffffff90 0% 0% no-repeat padding-box;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
  }
}

mat-icon.mat-icon.notranslate.arrow_back.material-icons.mat-icon-no-color {
  color: #55aaa0;
  font-size: 24px;
  cursor: pointer;
}

mat-icon.mat-icon.notranslate.arrow_backs.material-icons.mat-icon-no-color {
  color: #fff;
  background-color: #55AAA0;
  padding: 0.5rem;
  border-radius: 50%;
  margin-left: 1rem;
  margin-top: 1rem;

}

.input_bg {
  background: #2828281A 0% 0% no-repeat padding-box;
}

.modal_content {
  position: relative;
  border-radius: 30px;
  padding: 2rem;
}

.mat-dialog-container {
  box-shadow: unset;
  background: unset;
  color: rgba(0, 0, 0, 0.87);
}

mat-icon.mat-icon.notranslate.close_modal.material-icons.mat-icon-no-color {
  position: absolute;
  right: -1rem;
  top: -1rem;
  height: 3rem;
  width: 3rem;
  background: #f05c5c;
  font-size: 2.5rem;
  color: white;
  border-radius: 2rem;
  cursor: pointer;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: unset;

}

.mat-dialog-container main {
  padding: 1rem;
  border-radius: 2rem;
}

.mat-stepper-horizontal {
  width: 100%;
}

.mat-horizontal-content-container {
  overflow: hidden;
  padding: 0px !important;
}

.modal_bg {
  background: #ffffffde 0% 0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  backdrop-filter: blur(5px);
}

.mat-snack-bar-container {
  color: black;
  background: #ffffffde 0% 0% no-repeat padding-box;
  border-radius: 2rem !important;
  box-shadow: unset;
}

.mat-simple-snackbar {
  justify-content: center !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
  border-radius: 0px;
  width: 5px;
  max-width: 5px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
  width: 5px;
  padding: 5px 0;
  border-radius: 10px;
  display: none;
}

*:hover::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
  width: 5px;
  padding: 5px 0;
  border-radius: 10px;
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 0px;
  background: none;
}

.cdk-overlay-pane {
  margin-bottom: 3rem !important;
}

.tool_title {
  font-family: roboto, arial, helvetica !important;
  flex: 1;
  font-weight: 500 !important;
  font-size: 1.3rem !important;
  padding-top:1rem
}
.tool_title_parcelise {
  font-family: roboto, arial, helvetica !important;
  
  font-weight: 500 !important;
  font-size: 1.3rem !important;
  padding-top:1rem;
  text-align: center;
}
.tool_title_plot {
  font-family: roboto, arial, helvetica !important;
  flex: 1;
  font-weight: 500 !important;
  font-size: 1.3rem !important;
  padding-left: 2rem;
}

.full_header {
  display: flex;
    align-items: center;
    width: 100%;
    padding: 0rem 0 0.5rem 0;
}

mat-icon.mat-icon.notranslate.close_modal2.material-icons.mat-icon-no-color {
  position: absolute;
  right: -1rem;
  top: -1rem;
  height: 3rem;
  width: 3rem;
  font-size: 3rem;
  color: white;
  border-radius: 2rem;
  cursor: pointer;
  background: #f05c5c;
  color: white;
}
mat-icon.mat-icon.notranslate.close_modalYield.material-icons.mat-icon-no-color {
  position: absolute;
  right: -1rem;
  top: -1rem;
  height: 3rem;
  width: 3rem;
  font-size: 3rem;
  color: white;
  border-radius: 2rem;
  cursor: pointer;
  background: #f05c5c;
  color: white;
}

.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  background: none !important;
  box-shadow: none !important;
}

.freePackBtn {
  border: 2px solid #55AAA0;
  color: #55AAA0;
  font-family: barlow light, arial, helvetica;
  font-size: 18px;
  background-color: inherit;
  width: 22rem;
  padding: 1.3rem;
  margin-top: -4rem;

}

mat-icon.mat-icon.guest-delete.notranslate.material-icons.mat-icon-no-color {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none !important;
}

mat-icon.mat-icon.add-guest.notranslate.material-icons.mat-icon-no-color {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none !important;
  margin-right: 0.5rem;
}

mat-icon.mat-icon.back-guest-list-icon.notranslate.material-icons.mat-icon-no-color {
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none !important;
  font-weight: 700;
  margin-right: 0.5rem;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'barlowlight' !important;
}

.toogle_switch label.mat-checkbox-layout {
  width: 100%;
  text-align: left;
  display: flow-root;
}

app-legende {
  position: fixed;
  z-index: 1000;
  bottom: 0;
}

app-carte-block {
  flex: 1;
  margin: 0 1rem;
}

.leaflet-popup.custom-pop-up-weather {
  background: #55aaa0;
}

.leaflet-compass .compass-button {
  display: flex;
  justify-content: center;
  float: left;
  width: 40px;
  height: 36px;
  border-radius: 4px;
}

.leaflet-control.leaflet-compass {
  position: fixed;
    color: #1978cf;
    border-radius: 4px;
    background-color: white;
    box-shadow: none;
    position: fixed;
    z-index: 1000;
    bottom: 10.2rem;
    right: 3px;
}

// LEFLET RIGHT BOUSSOLE & MAP LAYER
.leaflet-top.leaflet-right {
  top: 15rem;
}

mat-icon.mat-icon.addUserIcon.notranslate.material-icons.mat-icon-no-color {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none !important;
  font-size: 60px;
}

mat-icon.returprofileIcon.mat-icon.notranslate.material-icons.mat-icon-no-color {
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none !important;
  margin-right: 0.5rem;
}

.notBtn {
  padding: 2rem;
  background: red
}

.snackStyle {
  background-color: #55AAA0;
  color: #FFFF;
  font-size: 18px;
  font-family: "barlowlight"
}

mat-icon.mat-icon.helpLogin.notranslate.material-icons.mat-icon-no-color {
  font-size: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none !important;
}

mat-icon.mat-icon.notingActionIcon.notranslate.material-icons.mat-icon-no-color {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none !important;
}

.light_grey {
  background: white;
  color: black;
}


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@import '~@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$mat-blue: (
  50: #e3f2fd,
  100: #55aaa0,
  200: #55aaa0,
  300: #55aaa0,
  400: #55aaa0,
  500: #55aaa0,
  600: #55aaa0,
  700: #55aaa0,
  800: #55aaa0,
  900: #55aaa0,
  A100: #55aaa0,
  A200: #55aaa0,
  A400: #55aaa0,
  A700: #55aaa0,
  contrast: (50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

.dashboard_icon-container.global_view.disabled {
  opacity: 0.2;
  pointer-events: none;
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$jool-primary: mat-palette($mat-indigo);
$jool-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$jool-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$jool-theme: mat-light-theme((color: (primary: $jool-primary,
        accent: $jool-accent,
        warn: $jool-warn,
      )));

app-heb-doweather-item {
  margin: 0 0.2rem;
}

@media screen and (max-width: 915px) {
  .packsContainer {
    height: 90vh;
  }

  .modal_bg {
    background: #ffffffde;
    border-radius: 11px;
    opacity: 1;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  .modal_content {
    position: relative;
    border-radius: 10px;
    padding: none;
  }

  .profileMobile {
    width: 2rem !important;
    height: 2rem !important;
  }

  .accountPop {
    height: 100vh;
    margin-top: 2rem !important;

  }


  app-register {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 0;

    .leaflet-control-measure-interaction.js-interaction {
      padding: 0.5rem;
      background: white;
      width: 17rem;
    }
  }

  .mat-step-text-label {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 10px;
  }

  .leaflet-touch .leaflet-bar {
    border: unset;
    background-clip: padding-box;
    padding-top: 1rem;
  }

  mat-icon.mat-icon.notranslate.close_modal.material-icons.mat-icon-no-color {
    position: absolute;
    position: absolute;
    right: -0.1rem;
    top: -0.1rem;
    height: 2rem;
    width: 2rem;
    background: #f05c5c;
    font-size: 2.5rem;
    color: white;
    border-radius: 2rem;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    background: #f05c5c;
    font-size: 2.5rem;
    color: white;
    border-radius: 2rem;
    cursor: pointer;
  }

  mat-icon.mat-icon.notranslate.close_modal2.material-icons.mat-icon-no-color {
    position: absolute;
    position: absolute;
    right: 0.5rem;
    top: 0.1rem;
    height: 2rem;
    width: 2rem;
    background: #f05c5c;
    font-size: 2.5rem;
    color: white;
    border-radius: 2rem;
    cursor: pointer;
    height: 1.6rem;
    width: 1.6rem;
    background: #f05c5c;
    font-size: 2rem;
    color: white;
    border-radius: 2rem;
    cursor: pointer;
  }

  .custom-modal {
    padding: 0rem;
    z-index: 99999999999;
    margin: auto;
  }

  // .leaflet-top.leaflet-left {
  //   position: absolute;
  //   left: 84vw;
  //   top: -21px;
  // }
  .leaflet-top.leaflet-left {
    position: absolute;
    left: -1vw;
    top: 3rem;
    display: flex;
    flex-direction: column;
    width: 3rem;
  }

  .leaflet-control-zoom.leaflet-bar.leaflet-control {
    display: flex;
    align-items: center;
    height: 6rem;
    width: 3rem;
  }
}

.theme-stroke-primary {
  stroke: #000000;
  stroke-width: 0.3em;
  // cursor:ew-resize;
}

.domain {
  display: none;
}

.histogram-container {
  cursor: pointer;
}
// mat-icon.mat-icon.notranslate.get-file-btn.material-icons.mat-icon-no-color {
//   color: #FFF;
//   font-size: 1.6rem;
//   display: block;
//   width: 100%;
//   height: 100%;
//   margin-top: 0.4rem;
// }

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($jool-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.countingActions{
  width: 5rem;
}

.project_title{
  padding: 0.5rem;
  //border-bottom: solid 1px rgb(69, 69, 69);
}
mat-icon.mat-icon.notranslate.search-icon.material-icons.mat-icon-no-color {
  font-size: 1.5rem;
  color: gray;
}

.notinIcon div {
  color: white;
  /* font-size: 0.8rem; */
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  background: black;
  padding: 0 0.2rem;
  /* text-shadow: 2px 2px 13px rgba(0, 0, 0, 0.8); */
}



ul.leaflet-draw-actions li a{
  text-decoration: none;
  padding: 0.5rem;
  color: #000;
}

.leaflet-draw-toolbar a {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
}

.fullscreen-icon {
  background-size: 44px 68px;
}