@media screen and (max-width: 915px) {
 
  .main-container {
    display: flex;
    flex-direction: column;
    overflow:scroll;
  }
  .leaflet-control {
    cursor: pointer;
    width: 17rem;
}

  .login_form {
    padding: 0;
  }

  app-register {
    padding: 0rem 0rem;
  }

  input, select, textarea {
    font-size: 16px !important;
  }

  .leaflet-right {
    right: 0;
    display: none;
  }

 
  .leaflet-control-zoom.leaflet-bar.leaflet-control { 
    flex-direction: column;
   
 }

 .leaflet-control-zoom.leaflet-bar.leaflet-control {
  height:unset;
}
app-main-header {
  display: none;
}
input.weather_btn.leaflet-control {
    top: -14px;
    right: 3px;
    border-radius: 0px;
}
a#polyline-measure-control {
  display: none;
}
.mat-slide-toggle-thumb {
  height: 15px !important;
  width: 15px !important;
}
.mat-slide-toggle-bar {
  width: 28px !important;
  height: 14px !important;
}
.mat-slide-toggle-thumb-container {
  top: -0.4px !important;
}
.blur_bg{
  background: #ffffffd4 0% 0% no-repeat padding-box;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
}
.cdk-overlay-pane {
  margin-bottom: 0rem !important;
  width: 100vw !important;
  max-width: 100vw !important;
}
.mat-dialog-container {
  display: block;
  padding: 24px 5px !important;
}
.modal_content {
  padding: 1rem 10px !important;
  border-radius: 10px;
}

mat-icon.mat-icon.notranslate.close_modal.material-icons.mat-icon-no-color {
  position: absolute;
    right: 1rem;
    top: 1rem;
    height: 2rem;
    width: 2rem;
    background: unset;
    font-size: 1.5rem;
    color: rgb(0 0 0 / 87%);
    border-radius: 2rem;
    cursor: pointer;
 }
 app-bashboard-header-item {
  width: 45%;
}
app-carte-block {
  width: -webkit-fill-available;
}

.mobile_height{
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
  min-height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  height: -webkit-fill-available;
}
.input_content {
  margin-top: -13rem;
}

}
